import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import NonworkingDaysForm from "./NonworkingDaysForm";
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';

export function NonworkingDays() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="nonworking-days"
            indexColumns={['day']}
            form={NonworkingDaysForm}
            search={true}
            deleteButton={false}
            icon={<ContactEmergencyIcon fontSize="large" />}
        />
    );
}
