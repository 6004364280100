import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button,
    MenuItem,
    Box,
    FormControlLabel,
    FormGroup,
    Checkbox,
    TableCell,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    FormControl
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import { ROUTES } from "src/utils/httpHelper";
import i18n from "src/i18n/i18n";
import * as Yup from "yup";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DesktopTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


interface FormProps {
    modalCloseCallback,
    onSuccessCallback
    data?: any
}

const validationSchema = Yup.object().shape({
    formality_id: Yup.string(),
    licence_type_id: Yup.string(),
    module_id: Yup.string()
        .required(i18n.t("VALIDATION.REQUIRED")),
    date_availability_details: Yup.array().of(
        Yup.object().shape({
            day: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
            time: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
            quotes: Yup.string().required(i18n.t("VALIDATION.REQUIRED")),
        }),
    )
});

const defaultValues = (data) => ({
    formality_id: data ? (data.formality_id ?? '-1') : '-1',
    licence_type_id: data ? (data.licence_type_id ?? '-1') : '-1',
    module_id: data ? data.module_id : '',
    date_availability_details: data ? data.date_availability_details : [
        {
            day: '',
            time: moment().set({ "hour": 8, "minute": 0 }).format('HH:mm:00'),
            quotes: '',
            weeks: []
        }
    ]
});

const Form: FC<FormProps> = ({ modalCloseCallback, onSuccessCallback, data }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [selectsData, setSelectsData] = useState({
        modules: [],
        licenceTypes: [],
        formalities: []
    });

    const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, weeks, index, setValue) => {
        let target = event.target;
        if (target.checked) {
            if (!weeks.find(weekNumber => weekNumber == target.value)) {
                weeks.push(parseInt(target.value));
                weeks.sort();
            }
        } else {
            if (weeks.find(weekNumber => weekNumber == target.value)) {
                weeks = weeks.filter(weekNumber => weekNumber != target.value + "").sort();
            }
        }
        setValue(`date_availability_details.[${index}].weeks`, weeks);
    };

    useEffect(() => {
        http.get(ROUTES.DATES_AVAILABILITY_SELECTS_DATA)
            .then((response: any) => {
                setSelectsData(response);
            })
            .catch(error => {
                enqueueSnackbar(
                    error.message != '' ? error.message :
                        'Hubo un error al obtener los datos necesarios para crear una disponibilidad de cita.\n' + error.exception,
                    { variant: 'error' });
            })
    }, [])

    const { handleSubmit, formState: { errors, isSubmitting }, control, reset, getValues, setValue, watch } = useForm({
        mode: 'all',
        resolver: yupResolver(validationSchema),
        defaultValues: defaultValues(data)
    });
    const { fields, remove } = useFieldArray({
        control,
        name: "date_availability_details"
    });
    const watchFieldArray = watch("date_availability_details");
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchFieldArray[index]
        };
    });

    useEffect(() => {
        reset(defaultValues(data));
    }, [data]);

    return (
        <form noValidate onSubmit={handleSubmit(async (formData) => {
            if (data) {
                await http.post(ROUTES.DATES_AVAILABILITY + '/' + data.id, { ...formData, _method: 'put' })
                    .then((response: any) => {
                        reset();
                        onSuccessCallback();
                        enqueueSnackbar(response.message, { variant: 'success' });
                    })
                    .catch((error) => {
                        console.error(error);
                        enqueueSnackbar(error.message, { variant: 'error' });
                    })
            } else {
                await http.post(ROUTES.DATES_AVAILABILITY, formData)
                    .then((response: any) => {
                        reset();
                        onSuccessCallback();
                        enqueueSnackbar(response.message, { variant: 'success' });
                    })
                    .catch((error) => {
                        console.error(error);
                        enqueueSnackbar(error.message, { variant: 'error' });
                    })
            }
        })}>
            <DialogContent
                dividers
                sx={{
                    p: 3,
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Controller
                            name="module_id"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    error={Boolean(errors.module_id)}
                                    fullWidth
                                    helperText={errors.module_id?.message.toString()}
                                    label={t("FIELDS.MODULE")}
                                    variant="outlined"
                                    select
                                    {...field}
                                >
                                    {selectsData.modules.map((module) => <MenuItem key={'a' + module.id} value={module.id}>{module.name}</MenuItem>)}
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="formality_id"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    error={Boolean(errors.formality_id)}
                                    fullWidth
                                    helperText={errors.formality_id?.message.toString()}
                                    label={t("FIELDS.FORMALITY")}
                                    variant="outlined"
                                    select
                                    {...field}
                                >
                                    <MenuItem selected={true} value={'-1'}>{t('FIELDS.ALL_EXCEPT_FIRST_TIME').toUpperCase()}</MenuItem>
                                    {selectsData.formalities.map((formality) => <MenuItem key={'c' + formality.id} value={formality.id}>{formality.name}</MenuItem>)}
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Controller
                            name="licence_type_id"
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    error={Boolean(errors.licence_type_id)}
                                    fullWidth
                                    helperText={errors.licence_type_id?.message.toString()}
                                    label={t("FIELDS.LICENCE_TYPE")}
                                    variant="outlined"
                                    select
                                    {...field}
                                >
                                    <MenuItem selected={true} value={'-1'}>{t('FIELDS.ALL').toUpperCase()}</MenuItem>
                                    {selectsData.licenceTypes.map((licenceType) => <MenuItem key={'b' + licenceType.id} value={licenceType.id}>{licenceType.name}</MenuItem>)}
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                let availableDays = getValues('date_availability_details');
                                availableDays.push(
                                    {
                                        day: '',
                                        time: moment().set({ "hour": 8, "minute": 0 }).format('HH:mm:00'),
                                        quotes: '',
                                        weeks: []
                                    }
                                );
                                setValue('date_availability_details', availableDays);
                            }}>Agregar disponibilidad</Button>
                    </Grid>
                </Grid>
                <TableContainer>
                    <Table sx={{ minWidth: 600, mt: 2 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" width="17%">Día</TableCell>
                                <TableCell align="center" width="17%">Hora</TableCell>
                                <TableCell align="center" width="17%">Citas</TableCell>
                                <TableCell align="center" width="40%">Semanas que se repite</TableCell>
                                <TableCell align="center" width="9%"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {controlledFields.map((availableDay, index) => (
                                <TableRow key={availableDay.id}>
                                    <TableCell align="center" component="th" scope="row">
                                        <Controller
                                            name={`date_availability_details.[${index}].day`}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    label="Día"
                                                    error={Boolean(errors.date_availability_details?.[index]?.day)}
                                                    fullWidth
                                                    helperText={errors.date_availability_details?.[index]?.day?.message}
                                                    placeholder="Día"
                                                    variant="outlined"
                                                    select
                                                    {...field}
                                                >
                                                    <MenuItem value={'Mon'}>Lunes</MenuItem>
                                                    <MenuItem value={'Tue'}>Martes</MenuItem>
                                                    <MenuItem value={'Wed'}>Miercoles</MenuItem>
                                                    <MenuItem value={'Thu'}>Jueves</MenuItem>
                                                    <MenuItem value={'Fri'}>Viernes</MenuItem>
                                                    <MenuItem value={'Sat'}>Sabado</MenuItem>
                                                    <MenuItem value={'Sun'}>Domingo</MenuItem>
                                                </TextField>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Controller
                                            name={`date_availability_details.[${index}].time`}
                                            control={control}
                                            render={({ field }) => (
                                                < DesktopTimePicker
                                                    label="Hora"
                                                    value={new Date(`2022-12-22 ${field.value}`)}
                                                    minutesStep={15}
                                                    onChange={(date) => {
                                                        field.onChange(moment(date).format('HH:mm:00'));
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        error={Boolean(errors.date_availability_details?.[index]?.time)}
                                                        fullWidth
                                                        helperText={errors.date_availability_details?.[index]?.time?.message}
                                                        placeholder="Hora"
                                                        variant="outlined"
                                                        {...field}
                                                    />}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Controller
                                            name={`date_availability_details.[${index}].quotes`}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    error={Boolean(errors.date_availability_details?.[index]?.quotes)}
                                                    fullWidth
                                                    helperText={errors.date_availability_details?.[index]?.quotes?.message}
                                                    placeholder="Citas"
                                                    variant="outlined"
                                                    onKeyDown={
                                                        (e) => {
                                                            if (e.key != "Backspace" && e.key != "Tab" && !(isFinite(parseInt(e.key)))) {
                                                                e.preventDefault();
                                                            }
                                                        }
                                                    }
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell align="center" component="th" scope="row">
                                        <Controller
                                            name={`date_availability_details.[${index}].weeks`}
                                            control={control}
                                            render={({ field: { onChange, value, ...field } }) => (
                                                <Fragment>
                                                    <FormControl style={{ alignItems: "center" }}>
                                                        <FormGroup aria-label="position" row>
                                                            <FormControlLabel
                                                                value="top"
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => { handleChangeCheckbox(e, value, index, setValue) }}
                                                                        value="1"
                                                                        checked={value?.includes(1)}
                                                                    />
                                                                }
                                                                label="Primera"
                                                                labelPlacement="top"
                                                                {...field}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                    <FormControl style={{ alignItems: "center" }}>
                                                        <FormGroup aria-label="position" row>
                                                            <FormControlLabel
                                                                value="top"
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => { handleChangeCheckbox(e, value, index, setValue) }}
                                                                        value="2"
                                                                        checked={value?.includes(2)}
                                                                        {...field}
                                                                    />
                                                                }
                                                                label="Segunda"
                                                                labelPlacement="top"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                    <FormControl style={{ alignItems: "center" }}>
                                                        <FormGroup aria-label="position" row>
                                                            <FormControlLabel
                                                                value="top"
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => { handleChangeCheckbox(e, value, index, setValue) }}
                                                                        value="3"
                                                                        checked={value?.includes(3)}
                                                                        {...field}
                                                                    />
                                                                }
                                                                label="Tercera"
                                                                labelPlacement="top"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                    <FormControl style={{ alignItems: "center" }}>
                                                        <FormGroup aria-label="position" row>
                                                            <FormControlLabel
                                                                value="top"
                                                                control={
                                                                    <Checkbox
                                                                        onChange={(e) => { handleChangeCheckbox(e, value, index, setValue) }}
                                                                        value="4"
                                                                        checked={value?.includes(4)}
                                                                        {...field}
                                                                    />
                                                                }
                                                                label="Cuarta"
                                                                labelPlacement="top"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Fragment>
                                            )}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {
                                            fields.length > 1 && <IconButton color="error" onClick={() => {
                                                remove(index);
                                            }}>
                                                <DeleteForeverIcon />
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions
                sx={{
                    p: 3,
                    display: 'flex',
                    justifyContent: !data ? 'space-between' : 'flex-end'
                }}
            >
                {
                    !data &&
                    <Button color="warning" onClick={() => reset()}>
                        {t("GENERAL.RESET")}
                    </Button>
                }
                <Box>
                    <Button color="secondary" onClick={modalCloseCallback}>
                        {t("GENERAL.CANCEL")}
                    </Button>
                    <Button
                        sx={{
                            ml: 2
                        }}
                        type="submit"
                        startIcon={
                            isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        disabled={isSubmitting}
                        variant="contained"
                    >
                        {t(!data ? "DATES-AVAILABILITY.CREATE" : 'DATES-AVAILABILITY.EDIT')}
                    </Button>
                </Box>
            </DialogActions>
        </form >
    )
}

export default Form;