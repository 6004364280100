import { FC, Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button,
    MenuItem,
    Box
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import anyToString from "src/utils/anyToString";

interface UsersFormProps {
    modalCloseCallback,
    onSuccessCallback
    data?: any
}

const UsersForm: FC<UsersFormProps> = ({ modalCloseCallback, onSuccessCallback, data }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        http.get('api/roles/for-select')
            .then((roles: any) => setRoles(roles))
            .catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' });
            });
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    username: data ? data.username : '',
                    email: data ? data.email : '',
                    role_id: data ? data.role_id : '',
                    active: data ? data.active : 1,
                    password: '',
                    password_confirmation: '',
                    submit: null
                }}
                validationSchema={
                    data ?
                        Yup.object().shape({
                            username: Yup.string()
                                .max(191)
                                .required(t("VALIDATION.REQUIRED")),
                            email: Yup.string()
                                .email(t("VALIDATION.EMAIL"))
                                .max(255)
                                .required(t("VALIDATION.REQUIRED")),
                            role_id: Yup.number()
                                .max(191)
                                .required(t("VALIDATION.REQUIRED"))
                        }) :
                        Yup.object().shape({
                            username: Yup.string()
                                .max(191)
                                .required(t("VALIDATION.REQUIRED")),
                            email: Yup.string()
                                .email(t("VALIDATION.EMAIL"))
                                .max(255)
                                .required(t("VALIDATION.REQUIRED")),
                            role_id: Yup.number()
                                .max(191)
                                .required(t("VALIDATION.REQUIRED")),
                            password: Yup.string()
                                .max(255)
                                .required(t("VALIDATION.REQUIRED")),
                            password_confirmation: Yup.string()
                                .max(255)
                                .required(t("VALIDATION.REQUIRED"))
                        })
                }
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        await http.post('api/users/' + data.id, { ..._values, _method: 'put' })
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        await http.post('api/users', _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.username && errors.username)}
                                        fullWidth
                                        helperText={touched.username && errors.username && anyToString(errors.username)}
                                        label={t("FIELDS.USERNAME")}
                                        name="username"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.username}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(
                                            touched.email && errors.email
                                        )}
                                        fullWidth
                                        helperText={touched.email && errors.email && anyToString(errors.email)}
                                        label={t("FIELDS.EMAIL")}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth={true}
                                        select
                                        label={t('FIELDS.ROLE_ID')}
                                        name="role_id"
                                        value={values.role_id}
                                        onChange={handleChange}
                                    >
                                        {roles.map((role) => (
                                            <MenuItem key={role.id} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.password && errors.password)}
                                        fullWidth
                                        helperText={touched.password && errors.password && anyToString(errors.password)}
                                        label={t("FIELDS.PASSWORD")}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password"
                                        value={values.password}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.password_confirmation && errors.password_confirmation)}
                                        fullWidth
                                        helperText={touched.password_confirmation && errors.password_confirmation && anyToString(errors.password_confirmation)}
                                        label={t("FIELDS.PASSWORD_CONFIRMATION")}
                                        name="password_confirmation"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type="password"
                                        value={values.password_confirmation}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth={true}
                                        select
                                        label={t('FIELDS.ACTIVE')}
                                        name="active"
                                        value={values.active}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={1} selected={true}>
                                            {t('USERS.ACTIVE.1')}
                                        </MenuItem>
                                        <MenuItem value={0}>
                                            {t('USERS.ACTIVE.0')}
                                        </MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                                display: 'flex',
                                justifyContent: !data ? 'space-between' : 'flex-end'
                            }}
                        >
                            {
                                !data &&
                                <Button color="warning" onClick={() => resetForm()}>
                                    {t("GENERAL.RESET")}
                                </Button>
                            }
                            <Box>
                                <Button color="secondary" onClick={modalCloseCallback}>
                                    {t("GENERAL.CANCEL")}
                                </Button>
                                <Button
                                    sx={{
                                        ml: 2
                                    }}
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t(!data ? "USERS.CREATE" : 'USERS.EDIT')}
                                </Button>
                            </Box>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default UsersForm;