import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import PublicIcon from '@mui/icons-material/Public';
import { DialogContent, Grid, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { Fragment, useState } from "react";

export function ChangeLog() {
    const { t } = useTranslation();
    const [iniDate, setInitDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1));
    const [finDate, setEndDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0));

    return (
        <CrudBase
            resource="change-log"
            indexColumns={['user', 'table', 'created_at']}
            customColumns={{
                'user': (user) => user.username,
                'before' : (before) => before.before,
            }}
            search={false}
            deleteButton={false}
            showModalHiddenColumns={['id', 'user_id', 'updated_at']}
            icon={<PublicIcon fontSize="large" />}
            Filters={
                (props) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h4">
                                    Filtros
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label={t("FIELDS.USER")}
                                    variant="outlined"
                                    defaultValue={''}
                                    onChange={(event) => {
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            user_id: event.target.value
                                        })
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label={t("FIELDS.TABLE")}
                                    variant="outlined"
                                    defaultValue={''}
                                    onChange={(event) => {
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            table: event.target.value
                                        })
                                    }}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DesktopDatePicker
                                    label="Fecha inicial"
                                    value={iniDate}
                                    onChange={(date) => {
                                        setInitDate(date);
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            ini_date: moment(date).utc().format('YYYY-MM-DD')
                                        })
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DesktopDatePicker
                                    label="Fecha final"
                                    value={finDate}
                                    onChange={(date) => {
                                        setEndDate(date);
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            fin_date: moment(date).utc().format('YYYY-MM-DD')
                                        })
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            }
        />
    );
}
