import { Grid } from "@mui/material";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { useTranslation } from "react-i18next";
import CrudBase from "src/components/CrudBase";
import RolesForm from "./RolesForm";

export function Roles() {
    return (
        <CrudBase
            resource="roles"
            indexColumns={['name', 'description']}
            showModalHiddenColumns={['permissions']}
            form={RolesForm}
            deleteButton={false}
            search={true}
            icon={<VerifiedUserIcon fontSize="large" />} />
    );
}
