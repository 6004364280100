import CrudBase from "src/components/CrudBase";
import PeopleIcon from '@mui/icons-material/People';
import { useTranslation } from "react-i18next";
import UsersForm from "./UsersForm";

export function Users() {
  const { t } = useTranslation();

  return (
    <CrudBase
      resource="users"
      indexColumns={['username', 'email', 'role']}
      customColumns={{
        'role': (role) => role.name,
        'active': (active) => t('USERS.ACTIVE.' + active)
      }}
      form={UsersForm}
      showModalHiddenColumns={['role_id']}
      search={true}
      deleteButton={false}
      icon={<PeopleIcon fontSize="large" />}
    />
  );
}
