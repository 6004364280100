import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Button, Grid, MenuItem, TextField, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import http, { downloadAppointmentsExcel, ROUTES } from "src/utils/httpHelper";
import { useSnackbar } from "notistack";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

function Dashboard() {
  const { t } = useTranslation();
  const [selectsData, setSelectsData] = useState({
    modules: [],
    licenceTypes: [],
    formalities: []
  });
  const [initDate, setInitDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0));
  const { enqueueSnackbar } = useSnackbar();
  const [isDownloading, setIsDownloading] = useState(false);
  const [time, setTime] = useState("");

  useEffect(() => {
    http.get(ROUTES.DATES_AVAILABILITY_SELECTS_DATA)
      .then((response: any) => {
        setSelectsData(response);
      })
      .catch(error => {
        enqueueSnackbar(
          error.message != '' ? error.message :
            'Hubo un error al obtener los datos necesarios para los filtros.\n' + error.exception,
          { variant: 'error' });
      })
  }, []);

  return (
    <CrudBase
      resource="appointments"
      indexColumns={['folio_id', 'name', 'phone', 'formality', 'date', 'time', 'module', 'assistance', 'created_at']}
      customColumns={{
        assistance: assistance => parseInt(assistance) ? 'Sí' : 'No',
        folio_id: folio_id => folio_id
      }}
      search={true}
      deleteButton={false}
      createButton={false}
      editButton={false}
      actions={false}
      Filters={(props) => {
        return (
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4">
                Filtros
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label={t("FIELDS.MODULE")}
                variant="outlined"
                select
                defaultValue={''}
                onChange={(event) => {
                  props.setFiltersData({
                    ...props.filtersData,
                    module_id: event.target.value
                  })
                }}
              >
                <MenuItem value={''}>SIN FILTRAR</MenuItem>
                {selectsData.modules.map((module) => <MenuItem key={'a' + module.id} value={module.id}>{module.name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label={t("FIELDS.LICENCE_TYPE")}
                variant="outlined"
                select
                defaultValue={''}
                onChange={(event) => {
                  props.setFiltersData({
                    ...props.filtersData,
                    licence_type_id: event.target.value
                  })
                }}
              >
                <MenuItem value={''}>SIN FILTRAR</MenuItem>
                <MenuItem value={'-1'}>GENERICO</MenuItem>
                {selectsData.licenceTypes.map((licenceType) => <MenuItem key={'b' + licenceType.id} value={licenceType.id}>{licenceType.name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label={t("FIELDS.FORMALITY")}
                variant="outlined"
                select
                defaultValue={''}
                onChange={(event) => {
                  props.setFiltersData({
                    ...props.filtersData,
                    formality_id: event.target.value
                  })
                }}
              >
                <MenuItem value={''}>SIN FILTRAR</MenuItem>
                <MenuItem value={'-1'}>GENERICO</MenuItem>
                {selectsData.formalities.map((formality) => <MenuItem key={'c' + formality.id} value={formality.id}>{formality.name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label={t("FIELDS.APPOINTMENT_TYPE")}
                variant="outlined"
                select
                defaultValue={3}
                onChange={(event) => {
                  props.setFiltersData({
                    ...props.filtersData,
                    search_type: event.target.value
                  })
                }}
              >
                <MenuItem value={0}>Citas completadas</MenuItem>
                <MenuItem value={1}>Citas completadas canceladas</MenuItem>
                <MenuItem value={2}>Citas no completadas</MenuItem>
                <MenuItem value={3}>Todo</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <DesktopDatePicker
                label="Fecha inicial"
                value={initDate}
                onChange={(date) => {
                  setInitDate(date);
                  props.setFiltersData({
                    ...props.filtersData,
                    ini_date: moment(date).utc().format('YYYY-MM-DD')
                  })
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DesktopDatePicker
                label="Fecha final"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  props.setFiltersData({
                    ...props.filtersData,
                    fin_date: moment(date).utc().format('YYYY-MM-DD')
                  })
                }}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                label="Horario"
                variant="outlined"
                select
                defaultValue={0}
                onChange={(event) => {
                  props.setFiltersData({
                    ...props.filtersData,
                    time: event.target.value
                  })
                }}
              >
                <MenuItem value={0}>Todo</MenuItem>
                <MenuItem value="07:00">07:00</MenuItem>
                <MenuItem value="07:30">07:30</MenuItem>
                <MenuItem value="08:00">08:00</MenuItem>
                <MenuItem value="08:30">08:30</MenuItem>
                <MenuItem value="09:00">09:00</MenuItem>
                <MenuItem value="09:30">09:30</MenuItem>
                <MenuItem value="10:00">10:00</MenuItem>
                <MenuItem value="10:30">10:30</MenuItem>
                <MenuItem value="11:00">11:00</MenuItem>
                <MenuItem value="11:30">11:30</MenuItem>
                <MenuItem value="12:00">12:00</MenuItem>
                <MenuItem value="12:30">12:30</MenuItem>
                <MenuItem value="13:00">13:00</MenuItem>
                <MenuItem value="13:30">13:30</MenuItem>
                <MenuItem value="14:00">14:00</MenuItem>
                <MenuItem value="14:30">14:30</MenuItem>
                <MenuItem value="15:00">15:00</MenuItem>
                <MenuItem value="15:30">15:30</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                type="submit"
                startIcon={
                  isDownloading ? <CircularProgress size="1rem" /> : null
                }
                disabled={isDownloading}
                variant="contained"
                size="large"
                fullWidth
                onClick={() => {
                  downloadAppointmentsExcel(ROUTES.APPOINTMENTS, { ...props.filtersData, export: 1 });
                }}
              >
                {t('APPOINTMENTS.EXPORT')}
              </Button>
            </Grid>
          </Grid>
        )
      }
      }
      icon={<ListAltIcon fontSize="large" />}
    />
  );
}

export default Dashboard;
