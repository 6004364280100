import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import DatesAvailabilityExceptionForm from "./DatesAvailabilityExceptionForm";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import PublicIcon from '@mui/icons-material/Public';
import React, { useEffect, useState } from "react";
import { Grid, MenuItem, TextField, Typography, Button } from "@mui/material";
import http, { ROUTES } from "src/utils/httpHelper";
import { useSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";

export function DatesAvailabilityException() {
    const { t } = useTranslation();
    const [date, setDate] = useState(null);
    const [clearFilters, setClearFilters] = useState(false);
    const [selectsData, setSelectsData] = useState({
        modules: [],
        licenceTypes: [],
        formalities: []
    });
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        http.get(ROUTES.DATES_AVAILABILITY_EXCEPTION_SELECTS_DATA)
            .then((response: any) => {
                setSelectsData(response);
            })
            .catch(error => {
                enqueueSnackbar(
                    error.message != '' ? error.message :
                        'Hubo un error al obtener los datos necesarios para los filtros.\n' + error.exception,
                    { variant: 'error' });
            })
    }, []);

    return (
        <CrudBase
            resource="dates-availability-exception"
            indexColumns={['module', 'licence_type', 'formality', 'date']}
            showModalHiddenColumns={['id', 'module_id', 'licence_type_id', 'formality_id']}
            customColumns={
                {
                    'module': (module) => module.name,
                    'licence_type': (licence_type) => licence_type ? licence_type.name : t(('FIELDS.ALL')).toUpperCase(),
                    'formality': (formality) => formality ? formality.name : "TODOS LOS TRÁMITES EXCEPTO PRIMERA VEZ",
                    'date_availability_details_exception': (day) => day.map((item, index) => (
                        <React.Fragment key={index}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><small>Horario: <span style={{ fontWeight: "lighter" }}>{item.time}</span></small></td>
                                        <td><small>Cantidad de citas: <span style={{ fontWeight: "lighter" }}>{item.quotes}</span></small></td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                        </React.Fragment>
                    ))
                }
            }
            form={DatesAvailabilityExceptionForm}
            search={false}
            deleteButton={true}
            icon={<PublicIcon fontSize="large" />}
            Filters={
                (props) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={8} sx={{ display: 'flex', alignItems: 'center'}}>
                                <Typography variant="h4">
                                    Filtros
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}>
                            {clearFilters ? (
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        window.location.reload();
                                        setClearFilters(false);
                                    }}
                                    startIcon={<CleaningServicesIcon />}
                                    >
                                    Limpiar Filtros
                                </Button>
                            ): null}

                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label={t("FIELDS.MODULE")}
                                    variant="outlined"
                                    select
                                    defaultValue={''}
                                    onChange={(event) => {
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            module_id: event.target.value,
                                        })
                                        if (event.target.value === "") {
                                            setClearFilters(false);
                                        } else {
                                            setClearFilters(true);
                                        }
                                    }}
                                >
                                    <MenuItem value={''}>SIN FILTRAR</MenuItem>
                                    {selectsData.modules.map((module) => <MenuItem key={'a' + module.id} value={module.id}>{module.name}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label={t("FIELDS.LICENCE_TYPE")}
                                    variant="outlined"
                                    select
                                    defaultValue={''}
                                    onChange={(event) => {
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            licence_type_id: event.target.value
                                        })
                                        if (event.target.value === "") {
                                            setClearFilters(false);
                                        } else {
                                            setClearFilters(true);
                                        }
                                    }}
                                >
                                    <MenuItem value={''}>SIN FILTRAR</MenuItem>
                                    {selectsData.licenceTypes.map((licenceType) => <MenuItem key={'b' + licenceType.id} value={licenceType.id}>{licenceType.name}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    fullWidth
                                    label={t("FIELDS.FORMALITY")}
                                    variant="outlined"
                                    select
                                    defaultValue={''}
                                    onChange={(event) => {
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            formality_id: event.target.value
                                        })
                                        if (event.target.value === "") {
                                            setClearFilters(false);
                                        } else {
                                            setClearFilters(true);
                                        }
                                    }}
                                >
                                    <MenuItem value={''}>SIN FILTRAR</MenuItem>
                                    {selectsData.formalities.map((formality) => <MenuItem key={'c' + formality.id} value={formality.id}>{formality.name}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <DatePicker
                                    label={t("FIELDS.DATE")}
                                    value={date}
                                    onChange={(date) => {
                                        setDate(date);
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            date: moment(date).utc().format('YYYY-MM-DD')
                                        })
                                        setClearFilters(true)
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </Grid>
                        </Grid>
                    )
                }
            }
        />
    );
}
