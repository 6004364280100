import {
  FC,
  ChangeEvent,
  MouseEvent,
  useState,
  useEffect,
  useRef,
  forwardRef,
  ReactElement,
  Ref,
  Fragment
} from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Checkbox,
  Divider,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  styled,
  Grid,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Avatar,
  CircularProgress,
  Fade,
  Zoom,
  Skeleton,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import TableRowsTwoToneIcon from "@mui/icons-material/TableRowsTwoTone";
import GridViewTwoToneIcon from "@mui/icons-material/GridViewTwoTone";
import http from "src/utils/httpHelper";
import useAuth from "src/hooks/useAuth";
import { useSnackbar } from "notistack";
import PageHeader from "../PageHeader";
import VisibilityIcon from '@mui/icons-material/Visibility';
import Text from "../Text";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import EditIcon from '@mui/icons-material/Edit';
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { Navigate } from "react-router-dom";
import { TransitionProps } from "@mui/material/transitions";
import { TransitionGroup } from 'react-transition-group';
import axios from "axios";
import anyToString from "src/utils/anyToString";
import { User } from "src/models/user";

const CardWrapper = styled(Card)(
  ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(["box-shadow"])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const ButtonError = styled(Button)(
  ({ theme }) => `
      background: ${theme.colors.error.main};
      color: ${theme.palette.error.contrastText};

      &:hover {
          background: ${theme.colors.error.dark};
      }
      `
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Zoom itemRef="input" ref={ref} {...props} />;
});

const ScaledOnHoverIconButton = styled(IconButton)(
  ({ theme }) => `
    &:hover {
      svg {
        transform: scale(1.5);
      }
    }
  `
)

const BulkActions = () => {
  const { t } = useTranslation();
  return (
    <Grid container display="flex">
      <Grid item xs={12}>
        <Typography variant="h5" color="text.secondary">
          {t('GENERAL.BULK_ACTIONS')}:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ButtonError
          size='small'
          startIcon={<DeleteTwoToneIcon />}
          variant="contained"
          sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
        >
          {t('GENERAL.BULK_DELETE')}
        </ButtonError>
        <Tooltip title={t('GENERAL.BULK_DELETE')} arrow sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
          <ScaledOnHoverIconButton
            color="error"
          >
            <DeleteTwoToneIcon fontSize="small" />
          </ScaledOnHoverIconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

interface CrudBaseProps {
  resource: string,
  indexColumns: string[],
  customColumns?: any,
  showModalHiddenColumns?: string[],
  form?: any,
  createButton?: boolean | ((user: User) => boolean),
  showButton?: boolean | ((user: User, item) => boolean),
  editButton?: boolean | ((user: User, item) => boolean),
  deleteButton?: boolean | ((user: User, item) => boolean),
  search?: boolean,
  actions?: boolean,
  CustomShow?: any,
  extraActions?: any,
  icon?: any,
  showBulkActions?: boolean,
  Filters?: any
}


const CrudBase: FC<CrudBaseProps> = ({
  resource,
  indexColumns,
  customColumns = [],
  showModalHiddenColumns = [],
  form,
  createButton = true,
  showButton = true,
  editButton = true,
  deleteButton = true,
  search = false,
  actions = true,
  CustomShow = null,
  extraActions = null,
  icon = null,
  showBulkActions = false,
  Filters = null }) => {

  //common
  const clickedButton = useRef(null);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const [toggleView, setToggleView] = useState<string | null>(isMobile ? "grid_view" : "table_view");
  const handleViewOrientation = (
    _event: MouseEvent<HTMLElement>,
    newValue: string | null
  ) => {
    setToggleView(newValue);
  };

  //form modal
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const formModalOpen = (itemId, ref) => {
    clickedButton.current = ref;
    if (itemId) {
      http.get('api/' + resource + '/' + itemId)
        .then((item) => {
          setSelectedItemData(item);
          setIsFormModalOpen(true);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    } else {
      setIsFormModalOpen(true);
      setSelectedItemData(null);
    }
  };
  const formModalClose = () => {
    setIsFormModalOpen(false);
    setSelectedItemData(null);
  };

  // show modal
  const [isShowModalOpen, setIsShowModalOpen] = useState(false);
  const showModalOpen = (itemId, ref) => {
    clickedButton.current = ref;
    http.get('api/' + resource + '/' + itemId)
      .then((item) => {
        setSelectedItemData(item);
        setIsShowModalOpen(true);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };
  const showModalClose = () => {
    setIsShowModalOpen(false);
    setSelectedItemData(null);
  };

  // delete modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const deleteModalOpen = (item, ref) => {
    clickedButton.current = ref;
    setIsDeleteModalOpen(true);
    setSelectedItemData(item);
  };
  const deleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };
  const deleteModalHandler = () => {
    http.delete('api/' + resource + '/' + selectedItemData.id)
      .then(() => {
        setIsDeleteModalOpen(false);
        if (items.length == 1 && page != 0) {
          setPage(page - 1);
        } else {
          getList();
        }
        enqueueSnackbar(t((resource + '.DELETED').toUpperCase()), {
          variant: 'success'
        });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      });
  };

  //index
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);
  const [query, setQuery] = useState<string>('');
  const [total, setTotal] = useState<number>(-1);
  const [items, setItems] = useState<any>([]);
  const [isLoadingItems, setIsLoadingItems] = useState(true);
  const firstRender = useRef(true);
  const fromQueryChange = useRef(false);
  const animationOnExit = useRef(true);
  const axiosAbortController = new AbortController();
  const [filtersData, setFiltersData] = useState([]);

  const getList = async () => {
    setIsLoadingItems(true);
    http.get<any[]>('/api/' + resource, {
      params: {
        per_page: limit,
        page: page + 1,
        search_word: query,
        ...filtersData
      },
      signal: axiosAbortController.signal
    })
      .then((paginatedItems: any) => {
        setItems(paginatedItems.data);
        setTotal(paginatedItems.total);
        setPage(paginatedItems.current_page - 1);
        setIsLoadingItems(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          console.error(error);
          setIsLoadingItems(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      })
      .finally(() => {
        animationOnExit.current = true;
      });
  }

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    fromQueryChange.current = true;
    setPage(0);
    setQuery(event.target.value);
  };

  const handlePageChange = (_event: any, newPage: number): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPage(0);
    setLimit(parseInt(event.target.value));
  };


  // bulk actions
  const [selectedItems, setSelected] = useState<string[]>([]);
  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelected(event.target.checked ? items.map((item) => item.id) : []);
  };

  const handleSelectOne = (
    _event: ChangeEvent<HTMLInputElement>,
    selectedId: string
  ): void => {
    if (!selectedItems.includes(selectedId)) {
      setSelected((prevSelected) => [...prevSelected, selectedId]);
    } else {
      setSelected((prevSelected) =>
        prevSelected.filter((id) => id !== selectedId)
      );
    }
  };

  // bulk actions
  const selectedBulkActions = selectedItems.length > 0;
  const selectedSome = selectedItems.length > 0 && selectedItems.length < items.length;
  const selectedAll = selectedItems.length === items.length;

  // effects
  useEffect(() => {
    animationOnExit.current = false;
    if (fromQueryChange.current) {
      return;
    }
    getList();
    return (() => {
      axiosAbortController.abort();
    })
  }, [limit, page, filtersData]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    const timeoutHandler = setTimeout(() => {
      fromQueryChange.current = false;
      getList();
    }, 800);

    return () => {
      axiosAbortController.abort();
      clearTimeout(timeoutHandler);
    }
  }, [query]);

  if (!user.can(resource + '.list')) {
    return <Navigate to="/status/403" />;
  }

  return (
    <Fragment>
      <PageHeader
        heading={t((resource + ".HEADING").toUpperCase())}
        subheading={t((resource + ".SUBHEADING").toUpperCase())}
        icon={icon}
        actions={
          <Box sx={{ mt: { xs: 2 } }} display='flex'>
            <ToggleButtonGroup
              sx={{
                mr: 2
              }}
              value={toggleView}
              exclusive
              onChange={handleViewOrientation}
            >
              <ToggleButton disableRipple value="table_view">
                <TableRowsTwoToneIcon />
              </ToggleButton>
              <ToggleButton disableRipple value="grid_view">
                <GridViewTwoToneIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            {
              (typeof createButton == 'boolean' ? createButton : createButton(user)) &&
              user.can(resource + '.create') &&
              <Button
                fullWidth
                onClick={(ref) => formModalOpen(null, ref)}
                variant="contained"
                startIcon={<AddTwoToneIcon fontSize="small" />}
              >
                {t((resource + '.CREATE').toUpperCase())}
              </Button>
            }
          </Box>
        }
      />

      <Grid
        sx={{
          px: 4,
          pb: 4
        }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        {
          Filters && <Grid item xs={12} md={12} lg={12}>
            <Filters filtersData={filtersData} setFiltersData={setFiltersData} />
          </Grid>
        }
        <Grid item xs={12}>
          {toggleView === "table_view" && (
            <Card>
              {
                search &&
                <Fragment>
                  <Box p={2}>
                    {!selectedBulkActions && (
                      <TextField
                        sx={{
                          m: 0,
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleQueryChange}
                        placeholder={t((resource + ".SEARCH").toUpperCase())}
                        value={query}
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                    {selectedBulkActions && <BulkActions />}
                  </Box>
                  <Divider />
                </Fragment>
              }


              {
                isLoadingItems && items.length === 0 ?
                  <Fragment>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            {indexColumns.map(column => <TableCell key={column}><Skeleton variant="text" /></TableCell>)}
                            <TableCell><Skeleton variant="text" /></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {[1, 2, 3].map(num =>
                            <TableRow key={num}>
                              {indexColumns.map(column => <TableCell key={column}><Skeleton variant="text" /></TableCell>)}
                              <TableCell width={(100 / (indexColumns.length + 1)) + '%'} key={'aaakkk'}>
                                <Stack direction={'row'} justifyContent='center'>
                                  <Skeleton variant="circular" width={30} height={30} sx={{ mr: 2 }} />
                                  <Skeleton variant="circular" width={30} height={30} sx={{ mr: 2 }} />
                                  <Skeleton variant="circular" width={30} height={30} sx={{ mr: 2 }} />
                                </Stack>
                              </TableCell>
                            </TableRow>)
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box p={2} display='flex' justifyContent={isLoadingItems ? 'space-between' : 'flex-end'}>
                      {isLoadingItems && <CircularProgress />}
                      <Skeleton variant="text" width={'20%'} />
                    </Box>
                  </Fragment>
                  : (
                    items.length === 0 ? (
                      <Typography
                        sx={{
                          py: 10,
                        }}
                        variant="h3"
                        fontWeight="normal"
                        color="text.secondary"
                        align="center"
                      >
                        {t("GENERAL.NO_RESULTS")}
                      </Typography>
                    ) : (
                      <Fragment>
                        <TableContainer>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                {showBulkActions && <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={selectedAll}
                                    indeterminate={selectedSome}
                                    onChange={handleSelectAll}
                                  />
                                </TableCell>}
                                {
                                  indexColumns.map(column => {
                                    return (<TableCell key={'th-' + column}>{t(('FIELDS.' + column).toUpperCase())}</TableCell>)
                                  })
                                }
                                {
                                  actions &&
                                  <TableCell align="center">{t("GENERAL.ACTIONS")}</TableCell>
                                }
                              </TableRow>
                            </TableHead>
                            <TransitionGroup component={TableBody} exit={animationOnExit.current}>
                              {
                                items.map((item) => {
                                  const isSelected = selectedItems.includes(item.id);
                                  return (
                                    <Fade key={'tr-anim-' + item.id}>
                                      <TableRow hover selected={isSelected}>
                                        {
                                          showBulkActions &&
                                          <TableCell padding="checkbox">
                                            <Checkbox
                                              checked={isSelected}
                                              onChange={(event) =>
                                                handleSelectOne(event, item.id)
                                              }
                                              value={isSelected}
                                            />
                                          </TableCell>
                                        }
                                        {
                                          indexColumns.map(column => {
                                            if (column in customColumns) {
                                              return (
                                                <TableCell key={'td-' + column}>
                                                  {customColumns[column](item[column])}
                                                </TableCell>
                                              )
                                            } else {
                                              return (
                                                <TableCell key={'td-' + column}>
                                                  <Typography>{anyToString(item[column])}</Typography>
                                                </TableCell>
                                              )
                                            }
                                          })
                                        }
                                        {
                                          actions &&
                                          <TableCell align="center">
                                            <Typography noWrap>
                                              {
                                                (typeof showButton == 'boolean' ? showButton : showButton(user, item)) &&
                                                <Tooltip title={t((resource + '.SHOW').toUpperCase())} arrow>
                                                  <ScaledOnHoverIconButton
                                                    color="info"
                                                    onClick={(ref) => showModalOpen(item.id, ref)}
                                                  >
                                                    <VisibilityIcon fontSize="small" />
                                                  </ScaledOnHoverIconButton>
                                                </Tooltip>
                                              }
                                              {
                                                (typeof editButton == 'boolean' ? editButton : editButton(user, item)) &&
                                                user.can(resource + '.edit') &&
                                                <Tooltip title={t((resource + '.EDIT').toUpperCase())} arrow>
                                                  <ScaledOnHoverIconButton
                                                    color="warning"
                                                    onClick={(ref) => formModalOpen(item.id, ref)}
                                                    style={{ transform: '' }}
                                                  >
                                                    <EditIcon fontSize="small" />
                                                  </ScaledOnHoverIconButton>
                                                </Tooltip>
                                              }
                                              {
                                                (typeof deleteButton == 'boolean' ? deleteButton : deleteButton(user, item)) &&
                                                user.can(resource + '.delete') &&
                                                <Tooltip title={t((resource + ".DELETE").toUpperCase())} arrow>
                                                  <ScaledOnHoverIconButton
                                                    onClick={(ref) => deleteModalOpen(item, ref)}
                                                    color="error"
                                                  >
                                                    <DeleteTwoToneIcon fontSize="small" />
                                                  </ScaledOnHoverIconButton>
                                                </Tooltip>
                                              }
                                              {extraActions ? extraActions(item, getList) : null}
                                            </Typography>
                                          </TableCell>
                                        }
                                      </TableRow>
                                    </Fade>
                                  );
                                })
                              }
                            </TransitionGroup>
                          </Table>
                        </TableContainer>
                        <Box p={2} display='flex' justifyContent={isLoadingItems ? 'space-between' : 'flex-end'}>
                          {isLoadingItems && <CircularProgress />}
                          <TablePagination
                            component="div"
                            count={total}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleLimitChange}
                            page={page}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[5, 10, 15]}
                          />
                        </Box>
                      </Fragment>
                    )
                  )
              }
            </Card>
          )}
          {toggleView === "grid_view" && (
            <Fragment>
              {
                (showBulkActions || search) &&
                <Card
                  sx={{
                    p: 2,
                    mb: 3,
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {items.length !== 0 && (
                      <Fragment>
                        {
                          showBulkActions &&
                          <Box display="flex" alignItems="center">
                            <Tooltip
                              arrow
                              placement="top"
                              title={t("Select all users")}
                            >
                              <Checkbox
                                checked={selectedAll}
                                indeterminate={selectedSome}
                                onChange={handleSelectAll}
                              />
                            </Tooltip>
                          </Box>
                        }
                        {selectedBulkActions && (
                          <Box flex={1} pl={2}>
                            <BulkActions />
                          </Box>
                        )}
                      </Fragment>
                    )}
                    {!selectedBulkActions && search && (
                      <TextField
                        sx={{
                          my: 0,
                          ml: items.length !== 0 && showBulkActions ? 2 : 0,
                        }}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchTwoToneIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleQueryChange}
                        placeholder={t((resource + ".SEARCH").toUpperCase())}
                        value={query}
                        size="small"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  </Box>
                </Card>
              }
              {items.length === 0 ? (
                <Typography
                  sx={{
                    py: 10,
                  }}
                  variant="h3"
                  fontWeight="normal"
                  color="text.secondary"
                  align="center"
                >
                  {t("GENERAL.NO_RESULTS")}
                </Typography>
              ) : (
                <Fragment>
                  <TransitionGroup component={Grid} container spacing={3} exit={animationOnExit.current}>
                    {items.map((item) => {
                      const isSelected = selectedItems.includes(item.id);

                      return (
                        <Fade key={item.id}>
                          <Grid item xs={12} sm={6} md={4} >
                            <CardWrapper
                              className={isSelected ? "Mui-selected" : ""}
                            >
                              <Box
                                sx={{
                                  position: "relative",
                                  zIndex: "2",
                                }}
                              >
                                <Box p={2} display="flex" alignItems="flex-start">
                                  <Grid container>
                                    {
                                      indexColumns.map(
                                        column =>
                                          <Fragment key={column}>
                                            <Grid item xs={12} sm={4}>
                                              {t(('FIELDS.' + column).toUpperCase())}:&nbsp;
                                            </Grid>
                                            <Grid item xs={12} sm={8}>
                                              <Text color="black">
                                                <b>{column in customColumns ? customColumns[column](item[column]) : anyToString(item[column])}</b>
                                              </Text>
                                            </Grid>
                                          </Fragment>
                                      )
                                    }
                                  </Grid>
                                </Box>
                                <Divider />
                                {
                                  actions &&
                                  <Box
                                    pl={2}
                                    py={1}
                                    pr={1}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent={showBulkActions ? "space-between" : 'end'}
                                  >
                                    {
                                      showBulkActions &&
                                      <Checkbox
                                        checked={isSelected}
                                        onChange={(event) =>
                                          handleSelectOne(event, item.id)
                                        }
                                        value={isSelected}
                                      />
                                    }
                                    <Typography noWrap>
                                      {
                                        showButton &&
                                        <Tooltip title={t((resource + '.SHOW').toUpperCase())} arrow>
                                          <ScaledOnHoverIconButton
                                            color="info"
                                            onClick={(ref) => showModalOpen(item.id, ref)}
                                          >
                                            <VisibilityIcon fontSize="small" />
                                          </ScaledOnHoverIconButton>
                                        </Tooltip>
                                      }
                                      {
                                        editButton &&
                                        user.can(resource + '.edit') &&
                                        <Tooltip title={t((resource + '.EDIT').toUpperCase())} arrow>
                                          <ScaledOnHoverIconButton
                                            color="warning"
                                            onClick={(ref) => formModalOpen(item.id, ref)}
                                          >
                                            <EditIcon fontSize="small" />
                                          </ScaledOnHoverIconButton>
                                        </Tooltip>
                                      }
                                      {
                                        deleteButton &&
                                        user.can(resource + '.delete') &&
                                        <Tooltip title={t((resource + ".DELETE").toUpperCase())} arrow>
                                          <ScaledOnHoverIconButton
                                            onClick={(ref) => deleteModalOpen(item, ref)}
                                            color="error"
                                          >
                                            <DeleteTwoToneIcon fontSize="small" />
                                          </ScaledOnHoverIconButton>
                                        </Tooltip>
                                      }
                                      {extraActions ? extraActions(item, getList) : null}
                                    </Typography>
                                  </Box>
                                }
                              </Box>
                            </CardWrapper>
                          </Grid>
                        </Fade>
                      );
                    })}
                  </TransitionGroup>
                  <Card
                    sx={{
                      p: 2,
                      mt: 3,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: isLoadingItems ? 'space-between' : 'flex-end',
                    }}
                  >
                    {isLoadingItems && <CircularProgress />}
                    <TablePagination
                      component="div"
                      count={total}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleLimitChange}
                      page={page}
                      rowsPerPage={limit}
                      rowsPerPageOptions={[5, 10, 15]}
                    />
                  </Card>
                </Fragment>
              )}
            </Fragment>
          )
          }
          {
            !toggleView && (
              <Card
                sx={{
                  textAlign: "center",
                  p: 3,
                }}
              >
                <Typography
                  align="center"
                  variant="h4"
                  fontWeight="normal"
                  color="text.secondary"
                  sx={{
                    my: 5,
                  }}
                  gutterBottom
                >
                  {t('GENERAL.SELECT_BETWEEN_VIEWS')}
                </Typography>
              </Card>
            )
          }
        </Grid >
      </Grid >

      {/* show modal */}
      < Dialog
        fullWidth
        maxWidth="md"
        open={isShowModalOpen}
        onClose={showModalClose}
        TransitionComponent={Transition}
        TransitionProps={{
          style: {
            transformOrigin: clickedButton.current?.clientX + 'px ' + clickedButton.current?.clientY + 'px 0'
          },
          timeout: 250
        }}
      >
        <DialogTitle
          sx={{
            p: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t((resource + '.SHOW').toUpperCase())}
          </Typography>
          <Typography variant="subtitle2">
            {t((resource + '.SHOW_DESC').toUpperCase())}
          </Typography>
        </DialogTitle>
        {
          (CustomShow && <CustomShow item={selectedItemData} />) ||
          <DialogContent>
            <Typography variant="subtitle2">
              {
                (() => {
                  let fieldList = [];
                  for (const property in selectedItemData) {
                    if (showModalHiddenColumns.includes(property)) {
                      continue;
                    }

                    if (selectedItemData[property]) {
                      fieldList.push(
                        <Grid key={property} container spacing={0}>
                          <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                            <Box pr={3}>
                              {t(('FIELDS.' + property).toUpperCase())}:
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={8} md={9} style={{ color: "black" }}>
                            {
                              property in customColumns ? customColumns[property](selectedItemData[property]) : <b> {anyToString(selectedItemData[property])}</b>
                            }
                          </Grid>
                        </Grid>
                      );
                    }
                  }
                  return fieldList;
                })()
              }
            </Typography>
          </DialogContent>
        }
        <DialogActions
          sx={{
            p: 3,
          }}
        >
          <Button color="secondary" onClick={showModalClose}>
            {t("GENERAL.CLOSE")}
          </Button>
        </DialogActions>
      </Dialog >

      {/* delete modal */}
      < Dialog
        open={isDeleteModalOpen}
        maxWidth="sm"
        fullWidth
        keepMounted
        onClose={deleteModalClose}
        TransitionComponent={Transition}
        TransitionProps={{
          style: {
            transformOrigin: clickedButton.current?.clientX + 'px ' + clickedButton.current?.clientY + 'px 0'
          },
          timeout: 250
        }}
      >
        <DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <AvatarError>
              <DeleteTwoToneIcon />
            </AvatarError>

            <Typography
              align="center"
              sx={{
                py: 4,
                px: 6,
              }}
              variant="h3"
            >
              {t((resource + '.DELETE_CONFIRM_MESSAGE').toUpperCase())}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            p: 3,
          }}
        >
          <Button
            variant="text"
            fullWidth
            onClick={deleteModalClose}
          >
            {t("GENERAL.CANCEL")}
          </Button>
          <ButtonError
            onClick={deleteModalHandler}
            variant="contained"
            fullWidth
          >
            {t((resource + '.DELETE').toUpperCase())}
          </ButtonError>
        </DialogActions>
      </Dialog >

      {/* form modal */}
      < Dialog
        fullWidth
        maxWidth="md"
        open={isFormModalOpen}
        onClose={formModalClose}
        TransitionComponent={Transition}
        TransitionProps={{
          style: {
            transformOrigin: clickedButton.current?.clientX + 'px ' + clickedButton.current?.clientY + 'px 0'
          },
          timeout: 250
        }}
      >
        <DialogTitle
          sx={{
            p: 3,
          }}
        >
          <Typography variant="h4" gutterBottom>
            {t((resource + (!selectedItemData ? '.CREATE' : '.EDIT')).toUpperCase())}
          </Typography>
          <Typography variant="subtitle2">
            {t((resource + (!selectedItemData ? '.CREATE_DESC' : '.EDIT_DESC')).toUpperCase())}
          </Typography>
        </DialogTitle>
        {
          form ? form({
            modalCloseCallback: formModalClose,
            onSuccessCallback: () => { formModalClose(); getList(); },
            data: selectedItemData
          }) : null
        }
      </Dialog >
    </Fragment >
  );
};

CrudBase.propTypes = {
  resource: PropTypes.string.isRequired,
  indexColumns: PropTypes.array.isRequired,
};

export default CrudBase;
