import { FC, Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button,
    MenuItem,
    Box
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import { ROUTES } from "src/utils/httpHelper";
import anyToString from "src/utils/anyToString";

interface IdentificationsFormProps {
    modalCloseCallback,
    onSuccessCallback
    data?: any
}

const IdentificationsForm: FC<IdentificationsFormProps> = ({ modalCloseCallback, onSuccessCallback, data }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <>
            <Formik
                initialValues={{
                    name: data ? data.name : '',
                    submit: null
                }}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string()
                            .max(191)
                            .required(t("VALIDATION.REQUIRED"))
                    })
                }
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        await http.post(ROUTES.IDENTIFICATIONS_RESOURCE + '/' + data.id, { ..._values, _method: 'put' })
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        await http.post(ROUTES.IDENTIFICATIONS_RESOURCE, _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name && anyToString(errors.name)}
                                        label={t("FIELDS.NAME")}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                                display: 'flex',
                                justifyContent: !data ? 'space-between' : 'flex-end'
                            }}
                        >
                            {
                                !data &&
                                <Button color="warning" onClick={() => resetForm()}>
                                    {t("GENERAL.RESET")}
                                </Button>
                            }
                            <Box>
                                <Button color="secondary" onClick={modalCloseCallback}>
                                    {t("GENERAL.CANCEL")}
                                </Button>
                                <Button
                                    sx={{
                                        ml: 2
                                    }}
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t(!data ? "IDENTIFICATIONS.CREATE" : 'IDENTIFICATIONS.EDIT')}
                                </Button>
                            </Box>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default IdentificationsForm;