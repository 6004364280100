import type { ReactNode } from 'react';
import { MenuItem } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import i18n from 'src/i18n/i18n';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PublicIcon from '@mui/icons-material/Public';
import ListAltIcon from '@mui/icons-material/ListAlt';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  badgeTooltip?: string;
  resource?: string;

  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}


const menuItems: MenuItems[] = [
  {
    heading: 'Menu',
    items: [
      {
        name: i18n.t('DASHBOARD.HEADING'),
        link: '/admin/dashboard',
        icon: <DashboardIcon />,
        resource: 'dashboard'
      },
      {
        name: i18n.t('ROLES.HEADING'),
        link: '/admin/roles',
        icon: <VerifiedUserIcon />,
        resource: 'roles'
      },
      {
        name: i18n.t('DATES-AVAILABILITY.HEADING'),
        link: '/admin/dates-availability',
        icon: <PublicIcon />,
        resource: 'dates-availability'
      },
      /*       {
              name: i18n.t('CITIZENS.HEADING'),
              link: '/admin/citizens',
              icon: <PeopleIcon />,
              resource: 'citizens'
            }, */
      {
        name: i18n.t('NONWORKING-DAYS.HEADING'),
        link: '/admin/nonworking-days',
        icon: <PeopleIcon />,
        resource: 'nonworking-days'
      },
      {
        name: i18n.t('USERS.HEADING'),
        link: '/admin/users',
        icon: <PeopleIcon />,
        resource: 'users'
      },
      {
        name: i18n.t('SETTINGS.HEADING'),
        link: '/admin/settings',
        icon: <SettingsApplicationsIcon />,
        resource: 'settings'
      },
      /*       {
              name: i18n.t('LICENCE-TYPES.HEADING'),
              link: '/admin/licence-types',
              icon: <CreditCardIcon />,
              resource: 'licence-types'
            }, */
      {
        name: i18n.t('MODULES.HEADING'),
        link: '/admin/modules',
        icon: <MeetingRoomIcon />,
        resource: 'modules'
      },
      /*       {
              name: i18n.t('FORMALITIES.HEADING'),
              link: '/admin/formalities',
              icon: <SummarizeIcon />,
              resource: 'formalities'
            }, */
      /*       {
              name: i18n.t('IDENTIFICATIONS.HEADING'),
              link: '/admin/identifications',
              icon: <ContactEmergencyIcon />,
              resource: 'identifications'
            }, */
      /*       {
              name: i18n.t('ADDRESS-VERIFICATION-DOCUMENTS.HEADING'),
              link: '/admin/address-verification-documents',
              icon: <FileOpenIcon />,
              resource: 'address-verification-documents'
            }, */
      {
        name: i18n.t('NATIONALITIES.HEADING'),
        link: '/admin/nationalities',
        icon: <PublicIcon />,
        resource: 'nationalities'
      },
      {
        name: i18n.t('CHANGE-LOG.HEADING'),
        link: '/admin/change-log',
        icon: <ListAltIcon />,
        resource: 'change-log'
      },
      {
        name: i18n.t('DATES-AVAILABILITY-EXCEPTION.HEADING'),
        link: '/admin/dates-availability-exception',
        icon: <PublicIcon />,
        resource: 'dates-availability-exception'
      },
    ]
  }
];

export default menuItems;
