import { FC, Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
    Grid,
    DialogActions,
    DialogContent,
    TextField,
    CircularProgress,
    Button,
    MenuItem,
    Box
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import { ROUTES } from "src/utils/httpHelper";
import anyToString from "src/utils/anyToString";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker } from "@mui/x-date-pickers";

interface NonworkingDaysFormProps {
    modalCloseCallback,
    onSuccessCallback
    data?: any
}

const NonworkingDaysForm: FC<NonworkingDaysFormProps> = ({ modalCloseCallback, onSuccessCallback, data }) => {
    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const initialValues = {
        day: data ? data.day : '',
        submit: null
    }
    const validationSchema = Yup.object().shape({
        day: Yup.date().nullable().required(t("VALIDATION.REQUIRED")),
    });

    const { formState: { errors }, control, setValue, getValues, trigger } = useForm({
        mode: 'all',
        defaultValues: initialValues,
        resolver: yupResolver(validationSchema),
      });

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        await http.post(ROUTES.NONWORKING_DAYS_RESOURCE + '/' + data.id, { ..._values, _method: 'put' })
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        await http.post(ROUTES.NONWORKING_DAYS_RESOURCE, _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    setFieldValue,
                    resetForm
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12}>
                                    <DatePicker
                                        label={t("FIELDS.DAY")}
                                        mask="__/__/____"
                                        value={new Date(moment(values.day).utc().format('YYYY/MM/DD'))}
                                        onChange={(date => {
                                            let date1 = moment(date).utc().format('YYYY-MM-DD');
                                            setFieldValue('day', date1);
                                        })}
                                        renderInput={(params) =>
                                        <TextField
                                            fullWidth={true}
                                            label={t('FIELDS.CLOSING_TIME')}
                                            name="day"
                                            value={values.day}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            {... params}
                                            error={Boolean(touched.day && errors.day)}
                                            helperText={touched.day && errors.day && anyToString(errors.day)}
                                        />
                                        }
                                    />

                                    
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions
                            sx={{
                                p: 3,
                                display: 'flex',
                                justifyContent: !data ? 'space-between' : 'flex-end'
                            }}
                        >
                            {
                                !data &&
                                <Button color="warning" onClick={() => resetForm()}>
                                    {t("GENERAL.RESET")}
                                </Button>
                            }
                            <Box>
                                <Button color="secondary" onClick={modalCloseCallback}>
                                    {t("GENERAL.CANCEL")}
                                </Button>
                                <Button
                                    sx={{
                                        ml: 2
                                    }}
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t(!data ? "NONWORKING-DAYS.CREATE" : 'NONWORKING-DAYS.EDIT')}
                                </Button>
                            </Box>
                        </DialogActions>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default NonworkingDaysForm;