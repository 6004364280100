import * as Yup from "yup";
import type { FC } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Checkbox,
  Typography,
  Link,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import useAuth from "src/hooks/useAuth";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import i18n from "src/i18n/i18n";
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t("VALIDATION.EMAIL"))
    .max(255)
    .required(i18n.t("VALIDATION.REQUIRED")),
  password: Yup.string()
    .max(255)
    .required(i18n.t("VALIDATION.REQUIRED")),
  remember: Yup.boolean()
});

const LoginForm: FC = () => {
  const { login } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  const { handleSubmit, formState: { errors, isSubmitting }, control } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      password: "",
      remember: false
    }
  });

  return (
    <form noValidate onSubmit={handleSubmit(async (formData) => {
      await login(formData.email, formData.password, formData.remember);
    })}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            error={Boolean(errors.email)}
            fullWidth
            margin="normal"
            helperText={errors.email?.message}
            label={t("FIELDS.EMAIL")}
            name="email"
            type="email"
            variant="outlined"
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <TextField
            error={Boolean(errors.password)}
            fullWidth
            margin="normal"
            helperText={errors.password?.message}
            label={t("FIELDS.PASSWORD")}
            name="password"
            type="password"
            variant="outlined"
            {...field}
          />
        )}
      />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
      >
        <Controller
          name="remember"
          control={control}
          render={({ field: { onChange, value, ...field } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name="remember"
                  color="primary"
                  onChange={onChange}
                  {...field}
                />
              }
              label={
                <>
                  <Typography variant="body2">
                    {t("FIELDS.REMEMBER")}
                  </Typography>
                </>
              }
            />
          )}
        />
        <Link component={RouterLink} to="/recover-password">
          <b>{t("LOGIN.LOST_PASSWORD")}</b>
        </Link>
      </Box>

      {Boolean(errors.remember) && (
        <FormHelperText error>{errors.remember?.message}</FormHelperText>
      )}

      <Button
        sx={{
          mt: 3
        }}
        color="primary"
        startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
        disabled={isSubmitting}
        type="submit"
        fullWidth
        size="large"
        variant="contained"
      >
        {t("LOGIN.HEADING")}
      </Button>
    </form>
  );
};

export default LoginForm;
