import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import CitizensForm from "./CitizensForm";
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import React from "react";

export function Citizens() {
    const { t } = useTranslation();

    return (
        <CrudBase
            resource="citizens"
            indexColumns={['name', 'last_name', 'second_last_name', 'CURP']}
            customColumns={{
                'nationality': (nationality) => <b>{nationality.name}</b>,
                'minor_tutor': (minor_tutor) => minor_tutor ? (
                    <React.Fragment>
                        <table>
                            <tr>
                                <th>Nombre:</th>
                                <td>{minor_tutor.name}</td>
                            </tr>
                            <tr>
                                <th>Apellidos:</th>
                                <td>{minor_tutor.last_name + " " + minor_tutor.second_last_name}</td>
                            </tr>
                            <tr>
                                <th>Genero:</th>
                                <td>{minor_tutor.sex}</td>
                            </tr>
                            <tr>
                                <th>CURP:</th>
                                <td>{minor_tutor.CURP}</td>
                            </tr>
                            <tr>
                                <th>Teléfono:</th>
                                <td>{minor_tutor.phone}</td>
                            </tr>
                            <tr>
                                <th>Correo:</th>
                                <td>{minor_tutor.email}</td>
                            </tr>
                        </table>
                    </React.Fragment>
                ) : null,
                "address": (address) => (
                    <React.Fragment>
                        <table style={{ width: "80%" }}>
                            <thead>
                                <tr>
                                    <th>Estado, Municipio</th>
                                    <th>Colonia, C.P.</th>
                                    <th>Dirección</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td align="center">{address.state.name + ", " + address.municipality.name}</td>
                                    <td align="center">{address.neighborhood.name + ", " + address.postal_code.code}</td>
                                    <td align="center">{address.prefix + " " + address.street + " " + address.ext_number + " " + (address.int_number ?? "")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                ),
                "emergency_contact": (emergency_contact) => (
                    <React.Fragment>
                        <table style={{ width: "80%" }}>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Apellidos</th>
                                    <th>Teléfono</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td align="center">{emergency_contact.name}</td>
                                    <td align="center">{emergency_contact.last_name + " " + emergency_contact.second_last_name}</td>
                                    <td align="center">{emergency_contact.phone}</td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                ),
                "citizen_foreigns": (citizen_foreigns) => citizen_foreigns ? (
                    <React.Fragment>
                        <b>ID: </b>{ citizen_foreigns.fm3 }
                    </React.Fragment>
                ) : null,
            }}
            showModalHiddenColumns={['id', 'created_at', 'updated_at', 'nationality_id']}
            form={CitizensForm}
            search={true}
            deleteButton={false}
            icon={<ContactEmergencyIcon fontSize="large" />}
        />
    );
}
