import React from "react";
import { pink } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Grid, Typography, TextField } from "@mui/material";
import Button, { ButtonProps } from '@mui/material/Button';
import http, { ROUTES } from "src/utils/httpHelper";
import { useSnackbar } from 'notistack';
import { Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate } from "react-router-dom";

function Block9() {

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText(pink[600]),
        backgroundColor: pink[600],
        '&:hover': {
            backgroundColor: pink[700],
        },
    }));

    const cancelAppointment = () => {
        http.post(ROUTES.CANCEL_APPOINTMENT, {
            id: id,
            CURP: curp
        })
            .then((response: any) => {
                setModalMessage(response.message);
                handleOpen();
            })
            .catch(error => {
                enqueueSnackbar(
                    error.message != '' ? error.message :
                        'Hubo un error al cancelar la cita.\n' + error.exception,
                    { variant: 'error' });
            })
            .finally(() => {

            })
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = React.useState(false);
    const [modalMessage, setModalMessage] = React.useState("");
    const [showFM3, setShowFM3] = React.useState(false);
    let navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        navigate("/");
    };
    const { enqueueSnackbar } = useSnackbar();
    let id = "", curp = "";

    return (
        <>

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h2" component="h2">
                        ¡Advertencia!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                </Box>
            </Modal>

            <Grid item sm={8} sx={{ textAlign: "center", backgroundColor: "#283A63", py: 1, color: "#fff" }}>
                <Typography variant="h1">
                    Cancelar cita
                </Typography>
                <Typography sx={{ mb: 1 }} variant="h3" style={{ fontWeight: 400, color: "#fff" }}>
                    Cancelaciones de citas
                </Typography>
            </Grid>

            <Grid container direction="column" spacing={0} alignItems="center" justifyContent="center" sx={{ mt: 5 }}>
                <Grid item sm={6} xs={8} sx={{ mt: 1.5 }}>
                    <TextField name="id" label="Folio de la cita" onChange={e => { id = e.target.value }} variant="outlined" fullWidth></TextField>
                </Grid>
                <Grid item sm={6} xs={8} sx={{ mt: 1.5 }}>
                    <TextField name="CURP" label="CURP" onChange={e => { curp = e.target.value }} variant="outlined" fullWidth></TextField>
                </Grid>
            </Grid>

            <Grid display="flex" sx={{ mt: 5, pb: 4, alignItems: "center", justifyContent: "center" }}>
                <Grid item sm={12}>
                    <ColorButton variant="contained" color="primary" sx={{ width: 200 }} onClick={cancelAppointment}>Cancelar cita</ColorButton>
                </Grid>
            </Grid>
        </>
    );
}

export default Block9;