import { FC, Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Grid,
  DialogActions,
  DialogContent,
  TextField,
  CircularProgress,
  Button,
  MenuItem,
  Box
} from "@mui/material";
import { useSnackbar } from "notistack";
import http from "src/utils/httpHelper";
import { ROUTES } from "src/utils/httpHelper";
import anyToString from "src/utils/anyToString";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { yupResolver } from '@hookform/resolvers/yup';

interface CitizensFormProps {
  modalCloseCallback,
  onSuccessCallback
  data?: any
}

const CitizensForm: FC<CitizensFormProps> = ({ modalCloseCallback, onSuccessCallback, data }) => {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();


  const generateVerificationDigit = (curp17: string) => {
    let dictionary = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ";
    let sum = 0;
    for (var i = 0; i < 17; i++)
      sum = sum + dictionary.indexOf(curp17.charAt(i)) * (18 - i);
    let digit = 10 - sum % 10;
    if (digit == 10) return 0;
    return digit;
  }

  const validationSchema = Yup.object().shape({
    formality_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    licence_type_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    module_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    appointment_date: Yup.date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required(t("VALIDATION.REQUIRED")),
    appointment_time: Yup.string()
      .required(t("VALIDATION.REQUIRED")),

    licences_year_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    CURP: Yup.string()
      .matches(/[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/, "CURP no válida")
      .test("invalid_verification_number", "CURP no válida", (curp, ctx) => {
        let verificationDigit = parseInt(curp.charAt(curp.length - 1));
        let curp17 = curp.slice(0, -1);
        return verificationDigit == generateVerificationDigit(curp17);
      })
      .required(t("VALIDATION.REQUIRED")),
    name: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    last_name: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    second_last_name: Yup.string(),
    sex: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    rfc: Yup.string()
      .required(t("VALIDATION.REQUIRED"))
      .matches(/^([A-Z\s]{4})\d{6}([A-Z\w]{3})?$/, "Colocar RFC válido"),
    birthdate: Yup.date()
      .typeError('Fecha no válida')
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .test('curp_date_birthdate_not_matching', 'La fecha no coincide con la de la curp', (value, ctx) => {
        return ctx.parent.curp.substr(4, 6) == moment(value).utc().subtract(1, 'days').format('YYMMDD');
      })
      .required(t("VALIDATION.REQUIRED")),
    marital_status: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    nationality_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    phone: Yup.string()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .matches(/[0-9]{10}/, "Colocar un número telefónico válido")
      .required(t("VALIDATION.REQUIRED")),
    email: Yup.string()
      .email(t('VALIDATION.EMAIL'))
      .required(t("VALIDATION.REQUIRED")),
    occupation: Yup.string()
      .required(t("VALIDATION.REQUIRED")),

    prefix: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    street: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    ext_number: Yup.number()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .required(t("VALIDATION.REQUIRED")),
    int_number: Yup.string()
      .transform((curr, orig) => orig === '' ? null : curr),
    postal_code: Yup.string()
      .nullable()
      .matches(/[0-9]{5}/, "Código postal no válido")
      .transform((curr, orig) => orig === '' ? null : curr)
      .required(t("VALIDATION.REQUIRED")),
    postal_code_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    state_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    municipality_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    neighborhood_id: Yup.string()
      .required(t("VALIDATION.REQUIRED")),

    contact_name: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    contact_last_name: Yup.string()
      .required(t("VALIDATION.REQUIRED")),
    contact_second_last_name: Yup.string(),
    contact_phone: Yup.string()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .matches(/[0-9]{10}/, "Colocar un número telefónico válido")
      .required(t("VALIDATION.REQUIRED")),

    tutor_name: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string().required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_last_name: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string().required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_second_last_name: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
        }
      }),
    tutor_phone: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .matches(/[0-9]{10}/, "Colocar un número telefónico válido")
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_curp: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .matches(/[A-Z]{1}[AEIOUX]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/, "CURP no válida")
            .test("invalid_verification_number", "CURP no válida", (curp, ctx) => {
              let verificationDigit = parseInt(curp.charAt(curp.length - 1));
              let curp17 = curp.slice(0, -1);
              return verificationDigit == generateVerificationDigit(curp17);
            })
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_email: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .email(t('VALIDATION.EMAIL'))
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_rfc: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
            .matches(/^([A-Z\s]{4})\d{6}([A-Z\w]{3})?$/, "Colocar RFC válido")
        }
      }),
    tutor_birthdate: Yup.string()
      .nullable()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .typeError('Fecha no válida')
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .test('curp_date_birthdate_not_matching', 'La fecha no coincide con la de la curp', (value, ctx) => {
              return ctx.parent.tutor_curp.substr(4, 6) == moment(value).utc().subtract(1, 'days').format('YYMMDD');
            })
            .required(t("VALIDATION.REQUIRED"))
        }
      }),

    tutor_prefix: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_street: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_int_number: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
        }
      }),

    tutor_ext_number: Yup.string()
      .nullable()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .nullable()
            .transform((curr, orig) => orig === '' ? null : curr)
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_postal_code: Yup.string()
      .nullable()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .nullable()
            .min(5, "Código postal no válido")
            .max(5, "Código postal no válido")
            .transform((curr, orig) => orig === '' ? null : curr)
            .required(t("VALIDATION.REQUIRED"))
        }
      }),

    tutor_postal_code_id: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_state_id: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_municipality_id: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_neighborhood_id: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_sex: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_marital_status: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
    tutor_nationality_id: Yup.string()
      .when("licence_type_id", (licenceTypeId) => {
        if (licenceTypeId == 6 || licenceTypeId == 7) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),

    fm3: Yup.string()
      .when("nationality_id", (nationality_id) => {
        if (nationality_id != 1) {
          return Yup.string()
            .required(t("VALIDATION.REQUIRED"))
        }
      }),
  });

  const initialValues = {
    name: data ? data.name : '',
    last_name: data ? data.last_name : '',
    second_last_name: data ? data.second_last_name : '',
    sex: data ? data.sex : '',
    CURP: data ? data.CURP : '',
    RFC: data ? data.RFC : '',
    birthdate: data ? data.birthdate : '',
    marital_status: data ? data.marital_status : '',
    nationality_id: data ? data.nationality_id : '',
    phone: data ? data.phone : '',
    email: data ? data.email : '',
    occupation: data ? data.occupation : '',

    address_prefix: data ? (data.address ? data.address.prefix : '') : '',
    address_street: data ? (data.address ? data.address.street : '') : '',
    address_int_number: data ? (data.address ? data.address.int_number : '') : '',
    address_ext_number: data ? (data.address ? data.address.ext_number : '') : '',
    address_neighborhood_id: data ? (data.address ? data.address.neighborhood_id : '') : '',
    address_postal_code_id: data ? (data.address ? data.address.postal_code_id : '') : '',
    address_state_id: data ? (data.address ? data.address.state_id : '') : '',
    address_municipality_id: data ? (data.address ? data.address.municipality_id : '') : '',

    emergency_contact_name: data ? (data.emergency_contact ? data.emergency_contact.name : '') : '',
    emergency_contact_last_name: data ? (data.emergency_contact ? data.emergency_contact.last_name : '') : '',
    emergency_contact_second_last_name: data ? (data.emergency_contact ? data.emergency_contact.second_last_name : '') : '',
    emergency_contact_phone: data ? (data.emergency_contact ? data.emergency_contact.phone : '') : '',

    foreigns_fm3: data ? (data.citizen_foreigns ? data.citizen_foreigns.fm3 : '') : '',

    minor_tutor_name: data ? (data.minor_tutor ? data.minor_tutor.name : '') : '',
    minor_tutor_last_name: data ? (data.minor_tutor ? data.minor_tutor.last_name : '') : '',
    minor_tutor_second_last_name: data ? (data.minor_tutor ? data.minor_tutor.second_last_name : '') : '',
    minor_tutor_phone: data ? (data.minor_tutor ? data.minor_tutor.phone : '') : '',
    minor_tutor_sex: data ? (data.minor_tutor ? data.minor_tutor.sex : '') : '',
    minor_tutor_email: data ? (data.minor_tutor ? data.minor_tutor.email : '') : '',
    minor_tutor_prefix: data ? (data.minor_tutor ? data.minor_tutor.prefix : '') : '',
    minor_tutor_street: data ? (data.minor_tutor ? data.minor_tutor.street : '') : '',
    minor_tutor_int_number: data ? (data.minor_tutor ? data.minor_tutor.int_number : '') : '',
    minor_tutor_ext_number: data ? (data.minor_tutor ? data.minor_tutor.ext_number : '') : '',
    minor_tutor_neighborhood_id: data ? (data.minor_tutor ? data.minor_tutor.neighborhood_id : '') : '',
    minor_tutor_postal_code_id: data ? (data.minor_tutor ? data.minor_tutor.postal_code_id : '') : '',
    minor_tutor_state_id: data ? (data.minor_tutor ? data.minor_tutor.state_id : '') : '',
    minor_tutor_municipality_id: data ? (data.minor_tutor ? data.minor_tutor.municipality_id : '') : '',
    minor_tutor_CURP: data ? (data.minor_tutor ? data.minor_tutor.CURP : '') : '',
    minor_tutor_RFC: data ? (data.minor_tutor ? data.minor_tutor.RFC : '') : '',
    minor_tutor_birthdate: data ? (data.minor_tutor ? data.minor_tutor.birthdate : '') : '',
    minor_tutor_marital_status: data ? (data.minor_tutor ? data.minor_tutor.marital_status : '') : '',
    minor_tutor_nationality_id: data ? (data.minor_tutor ? data.minor_tutor.nationality_id : '') : '',
    minor_tutor_occupation: data ? (data.minor_tutor ? data.minor_tutor.occupation : '') : '',

    submit: null
  }
  const { watch, formState: { errors }, control, setValue, getValues, trigger } = useForm({
    mode: 'all',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });


    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={
                    Yup.object().shape({
                        name: Yup.string()
                            .max(191)
                            .required(t("VALIDATION.REQUIRED"))
                    })
                }
                onSubmit={async (
                    _values,
                    { resetForm, setErrors, setStatus, setSubmitting }
                ) => {
                    if (data) {
                        await http.put(ROUTES.CITIZENS_RESOURCE + '/' + data.id, _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        await http.post(ROUTES.CITIZENS_RESOURCE, _values)
                            .then((response: any) => {
                                setStatus({ success: true });
                                resetForm();
                                onSuccessCallback();
                                enqueueSnackbar(response.message, { variant: 'success' });
                            })
                            .catch((error) => {
                                console.error(error);
                                enqueueSnackbar(error.message, { variant: 'error' });
                                setStatus({ success: false });
                                setErrors({ submit: error.message });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    touched,
                    values,
                    resetForm
                }) => (
                    <form onSubmit={handleSubmit}>
                        <DialogContent
                            dividers
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(touched.name && errors.name)}
                                        fullWidth
                                        helperText={touched.name && errors.name && anyToString(errors.name)}
                                        label={t("FIELDS.NAME")}
                                        name="name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(touched.last_name && errors.last_name)}
                                        fullWidth
                                        helperText={touched.last_name && errors.last_name && anyToString(errors.last_name)}
                                        label={t("FIELDS.LAST_NAME")}
                                        name="last_name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.last_name}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(touched.second_last_name && errors.second_last_name)}
                                        fullWidth
                                        helperText={touched.second_last_name && errors.second_last_name && anyToString(errors.second_last_name)}
                                        label={t("FIELDS.SECOND_LAST_NAME")}
                                        name="second_last_name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.second_last_name}
                                        variant="outlined"
                                    />
                                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(touched.sex && errors.sex)}
                    fullWidth
                    helperText={touched.sex && errors.sex && anyToString(errors.sex)}
                    label={t("FIELDS.SEX")}
                    name="sex"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.sex}
                    variant="outlined"
                    select>
                    <MenuItem value={'M'}>Masculino</MenuItem>
                    <MenuItem value={'F'}>Femenino</MenuItem>
                    <MenuItem value={'O'}>Otros</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(touched.CURP && errors.CURP)}
                    fullWidth
                    helperText={touched.CURP && errors.CURP && anyToString(errors.CURP)}
                    label={t("FIELDS.CURP")}
                    name="CURP"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{ maxLength: 18 }}
                    value={values.CURP}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(touched.RFC && errors.RFC)}
                    fullWidth
                    helperText={touched.RFC && errors.RFC && anyToString(errors.RFC)}
                    label={t("FIELDS.RFC")}
                    inputProps={{ maxLength: 13 }}
                    name="RFC"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.RFC}
                    variant="outlined"
                  />
                </Grid>

                                <Grid item xs={12} md={4}>
                                    <Controller
                                        name="birthdate"
                                        control={control}
                                        render={({ field, field: { onChange } }) => (
                                            <DatePicker
                                                label="Fecha de nacimiento"
                                                {...field}
                                                mask="__/__/____"
                                                onChange={(date => {
                                                    date = moment(date).utc().format('YYYY-MM-DD');
                                                    onChange(date);
                                                })}
                                                renderInput={(params) =>
                                                    <TextField {...params} sx={{ mt: 2 }} fullWidth
                                                        inputProps={{ ...params.inputProps, readOnly: true }}
                                                        error={!!errors.birthdate}
                                                        helperText={touched.birthdate && errors.birthdate && anyToString(errors.birthdate)} />
                                                }
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(touched.marital_status && errors.marital_status)}
                                        fullWidth
                                        helperText={touched.marital_status && errors.marital_status && anyToString(errors.marital_status)}
                                        label={t("FIELDS.MARITAL_STATUS")}
                                        name="marital_status"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.marital_status}
                                        variant="outlined"
                                        />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        error={Boolean(touched.nationality_id && errors.nationality_id)}
                                        fullWidth
                                        helperText={touched.nationality_id && errors.nationality_id && anyToString(errors.nationality_id)}
                                        label={t("FIELDS.NATIONALITY_ID")}
                                        name="nationality_id"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.nationality_id}
                                        variant="outlined"
                                    />
                                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    helperText={touched.phone && errors.phone && anyToString(errors.phone)}
                    label={t("FIELDS.PHONE")}
                    name="phone"
                    type="number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email && anyToString(errors.email)}
                    label={t("FIELDS.EMAIL")}
                    name="email"
                    type="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    error={Boolean(touched.occupation && errors.occupation)}
                    fullWidth
                    helperText={touched.occupation && errors.occupation && anyToString(errors.occupation)}
                    label={t("FIELDS.OCCUPATION")}
                    name="occupation"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.occupation}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              sx={{
                p: 3,
                display: 'flex',
                justifyContent: !data ? 'space-between' : 'flex-end'
              }}
            >
              {
                !data &&
                <Button color="warning" onClick={() => resetForm()}>
                  {t("GENERAL.RESET")}
                </Button>
              }
              <Box>
                <Button color="secondary" onClick={modalCloseCallback}>
                  {t("GENERAL.CANCEL")}
                </Button>
                <Button
                  sx={{
                    ml: 2
                  }}
                  type="submit"
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={Boolean(errors.submit) || isSubmitting}
                  variant="contained"
                >
                  {t(!data ? "CITIZENS.CREATE" : 'CITIZENS.EDIT')}
                </Button>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </>
  )
}

export default CitizensForm;