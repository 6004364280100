import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import DescriptionIcon from '@mui/icons-material/Description';
import { pink } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import { Container, Grid, Typography, Box, Divider, Alert, ListItemText, List, ListItem, ListItemIcon, FormControl, InputLabel, Select, MenuItem, FormHelperText, Paper } from "@mui/material";
import Block1 from 'src/components/Stepper/Texts/Block1';
import Block2 from 'src/components/Stepper/Texts/Block2';
import Block3 from 'src/components/Stepper/Texts/Block3';
import Block4 from 'src/components/Stepper/Texts/Block4';
import Block5 from 'src/components/Stepper/Texts/Block5';
import Block6 from 'src/components/Stepper/Texts/Block6';
import Block7 from 'src/components/Stepper/Texts/Block7';
import Block8 from 'src/components/Stepper/Texts/Block8';
import Block9 from 'src/components/Stepper/Texts/Block9';
import logo2 from "../../assets/img/logo2.png";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(pink[600]),
  backgroundColor: pink[600],
  '&:hover': {
    backgroundColor: pink[700],
  },
}));

function Info() {
  const [infoBlock, setInfoBlock] = useState("");
  const updateSelected = (e) => {
    setInfoBlock(e.target.value);
  };

  function Comps({ infoBlock }) {
    if (infoBlock === 1) {
      return (
        <Container><Block1 type={1} /></Container>
      );
    } else if (infoBlock === 2) {
      return (
        <Container><Block2 /></Container>
      );
    } else if (infoBlock === 3) {
      return (
        <Container><Block3 /></Container>
      );
    } else if (infoBlock === 4) {
      return (
        <Container><Block4 /></Container>
      );
    } else if (infoBlock === 5) {
      return (
        <Container><Block5 /></Container>
      );
    } else if (infoBlock === 6) {
      return (
        <Container><Block1 type={6} /></Container>
      );
    } else if (infoBlock === 7) {
      return (
        <Container><Block1 type={7} /></Container>
      );
    } else if (infoBlock === 8) {
      return (
        <Container><Block8 /></Container>
      );
    } else if (infoBlock === "9") {
      return (
        <Container><Block9 /></Container>
      );
    }
  }

  return (
    <>
      <div style={{ backgroundImage: `url(${logo2})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", minHeight: "95vh" }}>
        <Container>
          <Box style={{ marginTop: 20, textAlign: "center" }}>
            <img src={require('../../assets/img/logo.png')} width="25%" />
          </Box>
          <Divider style={{ marginTop: 10, marginBottom: 10 }}></Divider>
        </Container>

        <Container style={{ backgroundImage: (require('../../assets/img/logo.png')) }}>
          <Box>
            <Grid container direction="row" sx={{ pb: 2, mb: 0, textAlign: "center", justifyContent: "center" }}>
              <Grid item sm={12} md={12} lg={12}>
                <Typography variant="h1" sx={{ pb: 0 }}>
                  ¡Bienvenido!
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="h2" sx={{ pb: 2, pt: 0 }}>
                  Estás realizando un trámite para tu licencia de conducir:
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Selecciona el trámite que deseas realizar</InputLabel>
                  <Select label="Selecciona el trámite que deseas realizar" value={infoBlock} onChange={updateSelected}>
                    <MenuItem value="" disabled>
                      Selecciona el trámite que deseas realizar
                    </MenuItem>
                    <MenuItem value={1}>Primera vez</MenuItem>
                    <MenuItem value={2}>Renovación</MenuItem>
                    <MenuItem value={3}>Canje</MenuItem>
                    <MenuItem value={4}>Extravio</MenuItem>
                    <MenuItem value={5}>Robo</MenuItem>
                    <MenuItem value={6}>Extranjeros</MenuItem>
                    <MenuItem value={7}>Foráneos</MenuItem>
                    <MenuItem value={8}>Transportes</MenuItem>
                    <MenuItem value={"9"}>Cancelar Cita</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Comps infoBlock={infoBlock} />

        {!(infoBlock == "9" || infoBlock == "8") &&
          <Grid display="flex" sx={{ mt: 2, pb: 4, alignItems: "center", justifyContent: "center" }}>
            <Grid item sm={12}>
              <ColorButton variant="contained" color="primary" href={'/process?formality_id=' + infoBlock} sx={{ width: 200 }} disabled={!infoBlock}>Comenzar</ColorButton>
            </Grid>
          </Grid>
        }
        <Box>
          <Grid container direction="row" sx={{ pb: 2, mb: 0, textAlign: "center", justifyContent: "center" }}>
            <Grid item xs={6} md={6}>
              <Box style={{ marginTop: 5 }}>
                <img src={require('../../assets/img/sspe.png')} width="25%" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}

const styles = ({
  mainContainer: {
    flex: 1,
    backgroundColor: 'transparent',
    resizeMode: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
  }
});







export default Info;
