import React from "react";
import { Grid, Typography, FormControl, InputLabel, MenuItem, OutlinedInput, InputAdornment, TextField } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { blue } from '@mui/material/colors';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Controller } from "react-hook-form";
import moment from "moment";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

export default function FormTwo(props) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };
    const [open, setOpen] = React.useState(false);
    const [modalMessage, setModalMessage] = React.useState("");
    const [showFM3, setShowFM3] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const calculateAge = (date) => {
        let birthday = moment(date).utc();
        let now = moment().utc().add(1, "days");
        let age = now.diff(birthday, 'years');
        if (age >= 16) {
            if (props.generalDataController.getValues('licence_type_id') == 6 || props.generalDataController.getValues('licence_type_id') == 7) {
                if (age >= 18) {
                    setModalMessage("Favor de seleccionar la licencia correcta, se seleccionó menor de edad");
                    handleOpen();
                }
            } else {
                if (age < 18) {
                    setModalMessage("Favor de seleccionar la licencia correcta, se seleccionó mayor de edad");
                    handleOpen();
                }
            }
        } else {
            setModalMessage("Los menores de 16 años no pueden trámitar licencias");
            handleOpen();
        }
        props.generalDataController.setAge(age);
    }

    const getData = (curp) => {
        let array = [
            { sex: "", rfc: "", birthdate: null }
        ];

        //sex
        let sex = curp.charAt(10);

        if(sex == 'H'){
            array[0].sex = "M";
        }else if(sex == 'M'){
            array[0].sex = "F";
        }

        //rfc
        array[0].rfc = curp.substring(0, 10);

        //birthdate
        let extracted_birthdate = curp.substring(4, 10);
        let year = extracted_birthdate.substring(0, 2);
        let month = extracted_birthdate.substring(2, 4);
        let day = extracted_birthdate.substring(4, 6);

        if (parseInt(year) >= 0 && parseInt(year) <= 21) {
            year = '20' + year;
        } else {
            year = '19' + year;
        }

        array[0].birthdate = `${year}/${month}/${day}`;
        return array[0];
    }

    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                disableEnforceFocus
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h2" component="h2">
                        ¡Advertencia!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {modalMessage}
                    </Typography>
                </Box>
            </Modal>

            <Grid item sm={12}>
                <Typography variant="h1">
                    Llena el formulario
                </Typography>
            </Grid>

            <Typography sx={{ mt: 2 }} variant="h3" style={{ fontWeight: 400, display: 'flex', alignItems: 'center' }}>
                <AccountBoxIcon sx={{ mr: 1, color: blue[1000], fontSize: 30 }} />Datos de licencia
            </Typography>

            <Grid container direction="row" spacing={0} columnSpacing={5} rowSpacing={0}>

                <Grid item sm={3} xs={12}>
                    <Controller
                        name="licences_year_id"
                        control={props.control}
                        render={({ field, field: { onChange } }) => (
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                select
                                inputProps={{
                                    onChange: (event: any) => {
                                        onChange(event);
                                        let selectedLicenceYears = props.generalDataController.selectsData.selectedLicenceYears
                                            .find(years => years.id == event.target.value);
                                        props.generalDataController.setAmount(selectedLicenceYears.pivot.amount);
                                    }
                                }}
                                label="Vigencia"
                                {...field}
                                error={!!props.generalDataController.errors.licences_year_id}
                                helperText={props.generalDataController.errors.licences_year_id?.message}>
                                {
                                    props.generalDataController.selectsData.selectedLicenceYears
                                        .map((years) => <MenuItem key={years.id} value={years.id}>{years.years == 1 ? years.years + ' Año' : years.years + ' Años'}</MenuItem>)
                                }
                            </TextField>
                        )}
                    />
                </Grid>

                {/* <Grid item sm={3} xs={12}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Importe</InputLabel>
                        <OutlinedInput
                            readOnly={true}
                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            label="Importe"
                            value={props.generalDataController.amount}
                        />
                    </FormControl>
                </Grid> */}

            </Grid>

            <Typography sx={{ mt: 2 }} variant="h3" style={{ fontWeight: 400, display: 'flex', alignItems: 'center' }} >
                <AccountCircleIcon sx={{ mr: 1, color: blue[1000], fontSize: 30 }} />Datos generales
            </Typography>

            <Grid container direction="row" spacing={0} columnSpacing={5} rowSpacing={0}>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="curp"
                        control={props.control}
                        render={({ field, field: { onChange } }) => (
                            <TextField {...field} label="Curp" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase());
                                    if (valueWithoutAccents.length == 18) {
                                        let arr = getData(valueWithoutAccents);
                                            props.generalDataController.setValue('birthdate', arr.birthdate);
                                            props.generalDataController.setValue('sex', arr.sex);
                                            props.generalDataController.setValue('rfc', arr.rfc);
                                            calculateAge(arr.birthdate);
                                    }else if(valueWithoutAccents.length < 18){
                                        props.generalDataController.setValue('birthdate', null);
                                        props.generalDataController.setValue('sex', "");
                                        props.generalDataController.setValue('rfc', "");
                                        props.generalDataController.setAge("");
                                    }
                                }}
                                inputProps={{ maxLength: 18 }}
                                error={!!props.generalDataController.errors.curp}
                                helperText={props.generalDataController.errors.curp?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="name"
                        control={props.control}
                        render={({ field, field: {onChange} }) => (
                            <TextField label="Nombre(s)" {...field} variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase());
                                }}
                                error={!!props.generalDataController.errors.name}
                                helperText={props.generalDataController.errors.name?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="last_name"
                        control={props.control}
                        render={({ field, field: {onChange} }) => (
                            <TextField label="Primer Apellido" {...field} variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase());
                                }}
                                error={!!props.generalDataController.errors.last_name}
                                helperText={props.generalDataController.errors.last_name?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="second_last_name"
                        control={props.control}
                        render={({ field, field: {onChange} }) => (
                            <TextField label="Segundo Apellido" {...field} variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase());
                                }}
                                error={!!props.generalDataController.errors.second_last_name}
                                helperText={props.generalDataController.errors.second_last_name?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="sex"
                        control={props.control}
                        render={({ field }) => (
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                select
                                label="Sexo" {...field}
                                error={!!props.generalDataController.errors.sex}
                                helperText={props.generalDataController.errors.sex?.message}>
                                <MenuItem value={'M'}>Masculino</MenuItem>
                                <MenuItem value={'F'}>Femenino</MenuItem>
                                <MenuItem value={'O'}>Otros</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="rfc"
                        control={props.control}
                        render={({ field, field: { onChange } }) => (
                            <TextField {...field} label="R.F.C" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) =>{
                                    const value = e.target.value;
                                    const valueWithoutAccents = removeAccents(value);
                                    onChange(valueWithoutAccents.toUpperCase())
                                }}
                                inputProps={{ maxLength: 13 }}
                                error={!!props.generalDataController.errors.rfc}
                                helperText={props.generalDataController.errors.rfc?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                <Controller
                    name="birthdate"
                    control={props.control}
                    render={({ field }) => (
                        <DatePicker
                            label="Fecha de nacimiento"
                            {...field}
                            mask="__/__/____"
                            onChange={(date => {
                                date = moment(date).format('YYYY-MM-DD');
                                field.onChange(date);
                            })}
                            disabled
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        readOnly: true,
                                    }}
                                    error={!!props.generalDataController.errors.birthdate}
                                    helperText={props.generalDataController.errors.birthdate?.message}
                                />
                            )}
                        />
                    )}
                />


                </Grid>
                <Grid item sm={3} xs={12}>
                    <TextField
                        disabled
                        fullWidth
                        id="age"
                        label="Edad"
                        sx={{ mt: 2 }}
                        value={props.generalDataController.age}
                        error={!!props.generalDataController.errors.age}
                        helperText={props.generalDataController.errors.age?.message}></TextField>
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="marital_status"
                        control={props.control}
                        render={({ field }) => (
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                select
                                label="Estado civil"
                                {...field}
                                error={!!props.generalDataController.errors.marital_status}
                                helperText={props.generalDataController.errors.marital_status?.message}>
                                <MenuItem value={'Soltero'}>Soltero</MenuItem>
                                <MenuItem value={'Casado'}>Casado</MenuItem>
                                <MenuItem value={'Divorciado'}>Divorciado</MenuItem>
                                <MenuItem value={'Separación en proceso judicial'}>Separación en proceso judicial</MenuItem>
                                <MenuItem value={'Viudo'}>Viudo</MenuItem>
                                <MenuItem value={'Unión libre'}>Unión libre</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="phone"
                        control={props.control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                label="Teléfono"
                                variant="outlined" sx={{ mt: 2 }}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                onKeyDown={
                                    (e) => {
                                        if (e.key != "Backspace" && e.key != "Tab" && !(isFinite(parseInt(e.key)))) {
                                            e.preventDefault();
                                        }
                                    }
                                }
                                error={!!props.generalDataController.errors.phone}
                                helperText={props.generalDataController.errors.phone?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="email"
                        control={props.control}
                        render={({ field }) => (
                            <TextField {...field} type="email" label="Correo electrónico" variant="outlined" sx={{ mt: 2 }} fullWidth
                                onChange={(e) => {
                                    const valueWithoutAccents = removeAccents(e.target.value);
                                    field.onChange(valueWithoutAccents);
                                }}                              
                                error={!!props.generalDataController.errors.email}
                                helperText={props.generalDataController.errors.email?.message}></TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="occupation"
                        control={props.control}
                        render={({ field }) => (
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                select
                                label="Ocupación"
                                {...field}
                                error={!!props.generalDataController.errors.occupation}
                                helperText={props.generalDataController.errors.occupation?.message}>
                                <MenuItem value={'Empleado'}>Empleado</MenuItem>
                                <MenuItem value={'Estudiante'}>Estudiante</MenuItem>
                                <MenuItem value={'Independiente'}>Independiente</MenuItem>
                                <MenuItem value={'Jubilado'}>Jubilado</MenuItem>
                                <MenuItem value={'Desocupado'}>Desocupado</MenuItem>
                                <MenuItem value={'Ama de casa'}>Ama de casa</MenuItem>
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="nationality_id"
                        control={props.control}
                        render={({ field, field: { onChange } }) => (
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                ref="nationality_id"
                                select
                                label="Nacionalidad" {...field}
                                error={!!props.generalDataController.errors.nationality_id}
                                helperText={props.generalDataController.errors.nationality_id?.message}
                                inputProps={{
                                    onChange: (event: any) => {
                                        onChange(event);
                                        let selectedNationalities = props.generalDataController.selectsData.nationalities
                                            .find(nationality => nationality.id == event.target.value);

                                        if (selectedNationalities.id != 1) {
                                            setShowFM3(true);
                                        } else {
                                            setShowFM3(false);
                                        }
                                    }
                                }}
                            >
                                {
                                    props.generalDataController.selectsData.nationalities
                                        .map((nationality) => <MenuItem key={nationality.id} value={nationality.id}>{nationality.name}</MenuItem>)
                                }
                            </TextField>
                        )}
                    />
                </Grid>
                <Grid item sm={3} xs={12}>
                    <Controller
                        name="fm3"
                        control={props.control}
                        render={({ field }) => (
                            <TextField
                                style={showFM3 ? {} : { display: 'none' }}
                                fullWidth
                                {...field}
                                label="Forma FM3"
                                sx={{ mt: 2 }}
                                error={!!props.generalDataController.errors.fm3}
                                helperText={props.generalDataController.errors.fm3?.message}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
}