import CrudBase from "src/components/CrudBase";
import { useTranslation } from "react-i18next";
import DatesAvailabilityForm from "./DatesAvailabilityForm";
import PublicIcon from '@mui/icons-material/Public';
import React, { useEffect, useState } from "react";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import http, { ROUTES } from "src/utils/httpHelper";
import { useSnackbar } from "notistack";

export function DatesAvailability() {
    const { t } = useTranslation();
    const [selectsData, setSelectsData] = useState({
        modules: [],
        licenceTypes: [],
        formalities: []
    });
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        http.get(ROUTES.DATES_AVAILABILITY_SELECTS_DATA)
            .then((response: any) => {
                setSelectsData(response);
            })
            .catch(error => {
                enqueueSnackbar(
                    error.message != '' ? error.message :
                        'Hubo un error al obtener los datos necesarios para los filtros.\n' + error.exception,
                    { variant: 'error' });
            })
    }, []);

    return (
        <CrudBase
            resource="dates-availability"
            indexColumns={['module', 'licence_type', 'formality']}
            showModalHiddenColumns={['id', 'module_id', 'licence_type_id', 'formality_id']}
            customColumns={
                {
                    'module': (module) => module.name,
                    'licence_type': (licence_type) => licence_type ? licence_type.name : t(('FIELDS.ALL')).toUpperCase(),
                    'formality': (formality) => formality ? formality.name : "TODOS LOS TRÁMITES EXCEPTO PRIMERA VEZ",
                    'date_availability_details': (day) => day.map((item, index) => (
                        <React.Fragment key={index}>
                            <small>{t('DATES-AVAILABILITY.DAY-TEXT.' + item.day)}</small>
                            <table>
                                <tbody>
                                    <tr>
                                        <td><small>Horario: <span style={{ fontWeight: "lighter" }}>{item.time}</span></small></td>
                                        <td><small>Cantidad de citas: <span style={{ fontWeight: "lighter" }}>{item.quotes}</span></small></td>
                                        <td><small>Se repite: <span style={{ fontWeight: "lighter" }}>
                                            {
                                                item.weeks.length != 0 ? item.weeks.map((weekNumber, idx) => (
                                                    t('DATES-AVAILABILITY.WEEKS-TEXT.' + weekNumber).toUpperCase() + (idx != (item.weeks.length - 1) ? (
                                                        idx < (item.weeks.length - 2) ? ", " : t(('FIELDS.AND')).toUpperCase()
                                                    ) : "")
                                                )) : t(('FIELDS.ALL_POSSIBLE_TIMES')).toUpperCase()
                                            }
                                        </span></small></td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr />
                        </React.Fragment>
                    ))
                }
            }
            form={DatesAvailabilityForm}
            search={false}
            deleteButton={true}
            icon={<PublicIcon fontSize="large" />}
            Filters={
                (props) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                <Typography variant="h4">
                                    Filtros
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label={t("FIELDS.MODULE")}
                                    variant="outlined"
                                    select
                                    defaultValue={''}
                                    onChange={(event) => {
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            module_id: event.target.value
                                        })
                                    }}
                                >
                                    <MenuItem value={''}>SIN FILTRAR</MenuItem>
                                    {selectsData.modules.map((module) => <MenuItem key={'a' + module.id} value={module.id}>{module.name}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label={t("FIELDS.LICENCE_TYPE")}
                                    variant="outlined"
                                    select
                                    defaultValue={''}
                                    onChange={(event) => {
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            licence_type_id: event.target.value
                                        })
                                    }}
                                >
                                    <MenuItem value={''}>SIN FILTRAR</MenuItem>
                                    {selectsData.licenceTypes.map((licenceType) => <MenuItem key={'b' + licenceType.id} value={licenceType.id}>{licenceType.name}</MenuItem>)}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    label={t("FIELDS.FORMALITY")}
                                    variant="outlined"
                                    select
                                    defaultValue={''}
                                    onChange={(event) => {
                                        props.setFiltersData({
                                            ...props.filtersData,
                                            formality_id: event.target.value
                                        })
                                    }}
                                >
                                    <MenuItem value={''}>SIN FILTRAR</MenuItem>
                                    {selectsData.formalities.map((formality) => <MenuItem key={'c' + formality.id} value={formality.id}>{formality.name}</MenuItem>)}
                                </TextField>
                            </Grid>
                        </Grid>
                    )
                }
            }
        />
    );
}
